'use strict';

class SearchTableController {

  constructor($scope, Util) {
    this.sortReverse = $scope.$parent.sortReverse || false;
    this.searchString = '';
    this.$scope = $scope;
    this.Util = Util;
    this.sortType = "";
    let ctrl = this;
    this.$scope.initialSort = $scope.$parent.initialSort || $scope.tableStructure[0].value;
    this.lastSortField = this.$scope.initialSort;

    $scope.$watch('objects', function () {
      if ($scope.objects.length > 0) {
        if ($scope.initialSort != null) {
          ctrl.sortTable($scope.initialSort, "watch");
        } else {
          ctrl.sortTable($scope.tableStructure[0].value, "watch");
        }
      }
    });
  }

  filterObjects() {
    const searchString = this.searchString.replace(/\s/g, '').toLowerCase();
    if (searchString === '') {
      return this.$scope.objects;
    }

    var filteredObjects = [];
    for (var i = 0; i < this.$scope.objects.length; i++) {
      var object = this.$scope.objects[i];
      var respectsFilter = false;

      for (var j = 0; j < this.$scope.tableStructure.length; j++) {
        if (this.$scope.tableStructure[j].filter === 'alpha' && object[this.$scope.tableStructure[j].value] && object[this.$scope.tableStructure[j].value].toLowerCase) {
          if (object[this.$scope.tableStructure[j].value] && object[this.$scope.tableStructure[j].value].replace(/\s/g, '').toLowerCase().indexOf(searchString) > -1) {
            respectsFilter = true;
          }
        } else if (this.$scope.tableStructure[j].filter === 'num') {
          if (parseInt(searchString) === object[this.$scope.tableStructure[j].value]) {
            respectsFilter = true;
          }
        }
      }

      if (respectsFilter) {
        filteredObjects.push(object);
      }
    }
    return filteredObjects;
  }

  sortTable(type, event) {
    if (this.sortType === type && event === "click") {
      this.sortReverse = !this.sortReverse;
    }
    //this is to prevent the sorting field to reseted to the inital sort field when the data is updated (fetch).
    const sortBy = event === "click" ? type : this.lastSortField;
    this.sortType = sortBy;
    var reverse = this.sortReverse;
    let $scope = this.$scope;
    this.$scope.objects.sort(function (a, b) {

      if ($scope.archiveMode && a['archive'] && !b['archive']) {
        return 1;
      } else if ($scope.archiveMode && !a['archive'] && b['archive']) {
        return -1
      } else if (a[sortBy] < b[sortBy]) {
        return reverse ? 1 : -1;
      } else if (a[sortBy] > b[sortBy]) {
        return reverse ? -1 : 1;
      } else {
        if(a[$scope.initialSort] < b[$scope.initialSort])
          return -1;
        else if(a[$scope.initialSort] > b[$scope.initialSort])
          return 1;
        return 0;
      }
    });
    this.lastSortField = sortBy;
  }


  isBool(item) {
    return typeof (item) == "boolean";
  }

  getObjectValue(object, col) {
    if (col.filter === 'date') {
      return this.Util.getDateString(object[col.value]) + " " + object[col.value].getFullYear();
    } else if (col.filter === "datetime") {
      return this.Util.getDateTimeString(object[col.value]);
    } else if(col.filter === "num") {
      return Number(object[col.value]).toLocaleString();
    }else {
      return object[col.value];
    }
  }

  isArchive(object) {
    return this.$scope.archiveMode && object['archive'];
  }

  get searchFieldHolderClass() {
    if (!this.$scope.buttons) return "col-xs-12";
    const rowCount = 12 - (2 * this.$scope.buttons.length);
    return `col-xs-${rowCount}`;
  }

}

angular.module('cpformplastApp')
  .controller('SearchTableController', SearchTableController);
